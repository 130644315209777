<template>
    <div class="search-block">
        <div class="popcontext">
          <ul class="tab">
            <li >
              <b style="font-size:16px;">旅客服务</b>
            </li>
            <li
              rel="hgroup-booking"
              @click="selectPannel('0')"
              :class="activeIndex=='0'?'active':''"
              id="li_dynamic"
            >
              <span>全部</span>
            </li>
            <li
              rel="hgroup-booking"
              @click="selectPannel(item.keyWord)"
              :class="activeIndex==item.keyWord?'active':''"
              id="li_dynamic"
              v-for="item in keysGroup" v-bind:key="item.keyWord"
            >
              <span>{{item.keyWord}}</span>
            </li>
          </ul>
        </div>
      </div>
  </template>
  <script>
  import {pssgroup} from '@/api/newsapi'
  export default {
    data() {
    return {
      input: "",
      activeIndex:'0',
      keysGroup:[]
    };
   },
   mounted: function () {
        this.loadPssGroupData();
    },
  methods: {
    loadPssGroupData(){
          pssgroup({"aliasCode":"TCAT_NEWS"}).then(res => {
              if(res.success){
                this.keysGroup=res.data;
              }
        });
      },
    selectPannel(pannelId) {
     // console.log(pannelId);
      this.activeIndex=pannelId;
    }
  }
  }
  </script>
  <style scoped>
    
.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}
.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 45px;
  line-height: 45px;
  /* border-top: 1px solid #4e617f; */
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  border-top: 1px solid #d6003a;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}
.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d70039 !important;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}
  </style>